<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="min-height:400px; margin-top: 16px"
    >
      <el-form-item label="权限等级">
        <el-select
          v-model="form.level"
          placeholder="请选择权限等级"
          @change="ruleLimitLsit"
        >
          <el-option label="请选择权限等级" value=""></el-option>
          <el-option label="2级" value="2"></el-option>
          <el-option label="3级" value="3"></el-option>
          <el-option label="4级" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 0;">
        <el-input
          style="display: none;"
          v-model="form.level"
          placeholder="权限"
        />
      </el-form-item>
      <el-form-item v-show="form.level">
        <el-checkbox @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox v-model="Unfold" @change="handleCheckUnfold"
          >展开</el-checkbox
        >
      </el-form-item>
      <div v-show="form.level">
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          ref="tree"
          :default-checked-keys="checkedKeys"
          :default-expanded-keys="expandedKeys"
          default-expand-all
          :props="defaultProps"
        ></el-tree>
        <el-form-item style="text-align: center;">
          <el-button type="primary" @click="ruleLimit">确认</el-button>
        </el-form-item>
      </div>
    </el-form>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
// import DefaultTable from '@/components/DefaultTable';
// QueryDeptList,
import {
  ruleLimitSave,
  GetRuleLimit,
  GetIndexRule,
  queryRules
} from '@/api/department';

export default {
  components: {
    PageHeader
    // DefaultTable
  },
  data() {
    return {
      searchParams: {
        site_id: '',
        status: ''
      },
      // 提交数据
      form: {
        level: '',
        permi: ''
      },
      Unfold: true,
      expandedKeys: [], //默认展开一级
      checkedKeys: [], //默认选中
      data: [],
      defaultProps: {
        // children: 'children',
        label: 'text'
      },
      allRule: []
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.getRules();
  },
  methods: {
    toTree(list, parId) {
      let len = list.length;
      function loop(parId) {
        let res = [];
        for (let i = 0; i < len; i++) {
          let item = list[i];
          if (item.parent === parId) {
            item.children = loop(item.id);
            res.push(item);
          }
        }
        return res;
      }
      return loop(parId);
    },
    getRules() {
      queryRules().then(res => {
        this.allRule = res;
        this.data = this.toTree(res, '#');
      });
    },
    // 全选
    handleCheckAllChange(value) {
      if (value) {
        this.checkedKeys = [];
        this.data.forEach(item => {
          this.checkedKeys.push(item.id);
        });
      } else {
        this.checkedKeys = [];
      }
      this.$refs.tree.setCheckedKeys(this.checkedKeys);
    },
    // 展开
    handleCheckUnfold(value) {
      if (value) {
        this.expandedKeys = [];
        this.data.forEach(item => {
          this.expandedKeys.push(item.id);
        });
      } else {
        this.$nextTick(() => {
          for (
            var i = 0;
            i < this.$refs.tree.store._getAllNodes().length;
            i++
          ) {
            this.$refs.tree.store._getAllNodes()[i].expanded = value;
          }
        });
      }
    },
    // 部门权限设置
    ruleLimit() {
      this.form.permi = this.$refs.tree.getCheckedKeys().join(',');
      if (!this.form.level || !this.form.permi) {
        this.$message({
          message: '请选择权限等级和权限',
          type: 'warning'
        });
        return false;
      }

      var permiArr = this.form.permi;
      if (this.form.permi.indexOf(',') > -1) {
        permiArr = this.form.permi.split(',');
      }
      this.allRule.forEach(function(item) {
        if (
          item.parent != '#' &&
          permiArr.indexOf(item.id + '') > -1 &&
          permiArr.indexOf(item.parent + '') == -1
        ) {
          // this.form.permi += ',' + item.parent;
          permiArr.push(item.parent + '');
        }
      });
      this.form.permi = permiArr.join(',');
      // console.log(permiArr);
      console.log(this.form.permi);
      ruleLimitSave({
        level: this.form.level,
        permi: this.form.permi
      })
        .then(response => {
          if (response.code == 0) {
            this.$message({
              showClose: true,
              message: response.msg,
              type: 'success'
            });
            var that = this;
            setTimeout(function() {
              that.form.level = '';
            }, 1500);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    // 获取部门权限
    ruleLimitLsit() {
      var that = this;
      GetRuleLimit({
        level: that.form.level
      })
        .then(response => {
          if (response.code == 0) {
            var checkedPermi = response.data.permi.split(',');
            this.data.forEach(function(item) {
              var _id = item.id + '';
              if (checkedPermi.indexOf(_id) > -1 && item.children.length != 0) {
                checkedPermi.splice(checkedPermi.indexOf(_id), 1);
              }
            });
            // this.$refs.tree.setCheckedKeys(response.data.permi.split(','));
            this.$refs.tree.setCheckedKeys(checkedPermi);
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    },
    // 获取权限
    getIndexRule() {
      var that = this;
      GetIndexRule()
        .then(response => {
          that.data = response.menus;
        })
        .catch(error => {
          console.log('error', error);
        });
    }
    // handleSearch() {
    //   console.log('search');
    // },
    // handleQuery({ current, pageSize }) {
    //   const params = {
    //     page: current || 1,
    //     limit: pageSize || 10,
    //     ...this.searchParams
    //   };
    //   QueryDeptList(params).then(res => {
    //     const { data, msg, code } = res;
    //     if (code === 0) {
    //       this.data = data;
    //       this.pagination = {
    //         ...this.pagination,
    //         total: res.count
    //       };
    //     } else {
    //       this.$message({
    //         type: 'error',
    //         message: msg
    //       });
    //     }
    //   });
    // }
  }
};
</script>
