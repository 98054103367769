import request from '@/utils/request';

export function QueryDeptList(params) {
  return request({
    url: '/depart/data',
    method: 'get',
    params
  });
}

// export function QueryDept(params) {
//     return request({
//         url: '/depart/export'
//     })
// }

export function ruleLimitSave(params) {
  return request({
    url: '/params/rule_limit',
    method: 'GET',
    params: params
  });
}

export function queryRules(params) {
  return request({
    url: '/params/tree',
    method: 'GET',
    params
  });
}

export function GetRuleLimit(params) {
  return request({
    url: '/params/get_rule_limit',
    method: 'GET',
    params: params
  });
}

export function GetIndexRule() {
  return request({
    url: '/index/index_rule',
    method: 'GET'
    // params: params
  });
}
